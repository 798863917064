<template>
    <div>
        <v-row align="stretch">
            <v-col cols="12" lg="6" xl="4">
                <PlantillaEstadisticaModalidad 
                    :is-loading="estadisticasMontoPlanificado.isLoading"
                    :sumatoria="estadisticasMontoPlanificado.data?.monto ?? 0"
                    titulo="Monto planificado"
                    :modalidades="estadisticasMontoPlanificado.data?.modalidades ?? []"    
                />
            </v-col>
            <v-col cols="12" lg="6" xl="4">
                <PlantillaEstadisticaModalidad 
                    :is-loading="estadisticasMontoProyectado.isLoading"
                    :sumatoria="estadisticasMontoProyectado.data?.monto ?? 0"
                    titulo="Monto proyectado"
                    :modalidades="estadisticasMontoProyectado.data?.modalidades ?? []"    
                />
            </v-col>
            <v-col cols="12" lg="6" xl="4">
                <PlantillaEstadisticaModalidad 
                    :is-loading="estadisticasMontoCertificado.isLoading"
                    :sumatoria="estadisticasMontoCertificado.data?.monto ?? 0"
                    titulo="Monto certificado"
                    :modalidades="estadisticasMontoCertificado.data?.modalidades ?? []"    
                />
            </v-col>

            <v-col cols="12" lg="6">
                <PlantillaEstadisticaModalidad 
                    :is-loading="estadisticasMontoAdjudicado.isLoading"
                    :sumatoria="estadisticasMontoAdjudicado.data?.monto ?? 0"
                    titulo="Monto adjudicado"
                    :modalidades="estadisticasMontoAdjudicado.data?.modalidades ?? []"    
                />
            </v-col>

            <v-col cols="12" lg="6">
                <PlantillaEstadisticaModalidad 
                    :is-loading="estadisticasMontoContratado.isLoading"
                    :sumatoria="estadisticasMontoContratado.data?.monto ?? 0"
                    titulo="Monto contratado"
                    :modalidades="estadisticasMontoContratado.data?.modalidades ?? []"    
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import { createLoadable, toggleLoadable, setLoadableResponse } from '@/utils/loadable';
import PlantillaEstadisticaModalidad from './PlantillaEstadisticaModalidad.vue';

export default {
    name: 'MontosModalidades',
    components: { PlantillaEstadisticaModalidad },
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
        evitarPreCarga: { type: Boolean, default: false },
    },
    data: () => ({
        estadisticasMontoProyectado: createLoadable(null),
        estadisticasMontoCertificado: createLoadable(null),
        estadisticasMontoAdjudicado: createLoadable(null),
        estadisticasMontoContratado: createLoadable(null),
        estadisticasMontoPlanificado: createLoadable(null),
    }),
    methods: {
        manejarEventos(evento, payload) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarEstadisticasMontoProyectado(payload);
            this.cargarEstadisticasMontoCertificado(payload);
            this.cargarEstadisticasMontoAdjudicado(payload);
            this.cargarEstadisticasMontoContratado(payload);
            this.cargarEstadisticasMontoPlanificado(payload);
        },
        // DATA
        async cargarEstadisticasMontoProyectado(filtros) {
            toggleLoadable(this.estadisticasMontoProyectado);
            const { data } = await this.services.ReporteriaModalidadesCompra.cargarEstadisticasMontoProyectado(filtros);
            setLoadableResponse(this.estadisticasMontoProyectado, data);
        },
        async cargarEstadisticasMontoCertificado(filtros) {
            toggleLoadable(this.estadisticasMontoCertificado);
            const { data } = await this.services.ReporteriaModalidadesCompra.cargarEstadisticasMontoCertificado(filtros);
            setLoadableResponse(this.estadisticasMontoCertificado, data);
        },
        async cargarEstadisticasMontoAdjudicado(filtros) {
            toggleLoadable(this.estadisticasMontoAdjudicado);
            const { data } = await this.services.ReporteriaModalidadesCompra.cargarEstadisticasMontoAdjudicado(filtros);
            setLoadableResponse(this.estadisticasMontoAdjudicado, data);
        },
        async cargarEstadisticasMontoContratado(filtros) {
            toggleLoadable(this.estadisticasMontoContratado);
            const { data } = await this.services.ReporteriaModalidadesCompra.cargarEstadisticasMontoContratado(filtros);
            setLoadableResponse(this.estadisticasMontoContratado, data);
        },
        async cargarEstadisticasMontoPlanificado(filtros) {
            toggleLoadable(this.estadisticasMontoPlanificado);
            const { data } = await this.services.ReporteriaModalidadesCompra.cargarEstadisticasMontoPlanificado(filtros);
            setLoadableResponse(this.estadisticasMontoPlanificado, data);
        },
    },
    created() {
        if (!this.evitarPreCarga) {
            this.cargarEstadisticasMontoProyectado();
            this.cargarEstadisticasMontoCertificado();
            this.cargarEstadisticasMontoAdjudicado();
            this.cargarEstadisticasMontoContratado();
            this.cargarEstadisticasMontoPlanificado();
        }
        
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>